// @import "../utilities/variables"; // Ensure variables are imported
// @import "../utilities/mixins"; // Import mixins for spacing and media queries

// Grid System

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -spacer(2);
}

.col {
  flex: 1 1 0%;
  max-width: 100%;
  padding: 0 spacer(2);
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex: 0 0 percentage($i / 12);
    max-width: percentage($i / 12);
  }
}

@media (max-width: map-get($breakpoints, "md")) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      flex: 0 0 percentage($i / 12);
      max-width: percentage($i / 12);
    }
  }
}

@media (max-width: map-get($breakpoints, "sm")) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      flex: 0 0 percentage($i / 12);
      max-width: percentage($i / 12);
    }
  }
}
