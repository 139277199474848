@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.tableContainerWrapper {
  width: 100%;
  overflow-x: auto; // Enables horizontal scrolling on small screens
  -webkit-overflow-scrolling: touch; // Smooth scrolling for iOS devices

  .tableContainer {
    min-width: 900px; // Ensures consistent width for alignment
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;

    .tableHeader {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      background-color: #f1f1f1;
      font-weight: bold;
      padding: 10px;
      border-bottom: 2px solid #ddd;
      text-align: center;
    }

    .tableBody {
      .tableRow {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 10px;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #ddd;

        &:last-child {
          border-bottom: none;
        }

        .imageColumnContainer {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .rowData {
          text-align: center;
          color: #333;
        }

        .actionsContainer {
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;

          .actionButton {
            padding: 5px 10px;
            font-size: 0.9rem;
            border-radius: 4px;
            border: none;
            cursor: pointer;
            @include transition(all, 0.3s, ease);

            &.greenButton {
              background-color:$success-color;
              a {
                color: #fff;
              }
              &:hover {
                background-color: $success-color;
              }
            }

            &.yellowButton {
              background-color: $warning-color;
              color: #fff;
              &:hover {
                background-color: $warning-color;
              }
            }
            &.redButton {
              background-color: $danger-color;
              color: #fff;
              &:hover {
                background-color: $danger-color;
              }
            }

            &.disabled {
              background-color: #6c757d;
              &:hover {
                background-color: #6c757d;
              }
            }
          }
        }
      }
    }
  }
}

// 📱 Mobile Fix: Enable X Scroll
@media (max-width: 768px) {
  .tableContainerWrapper {
    overflow-x: auto;
  }
  .tableContainer {
    min-width: 900px;
  }
}
