@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.shoppingCartGridItemsTableContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tableTitlesContainer {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  border-bottom: 1px solid #ccccccb3;
  padding: 1rem 0;

  @include respond-to(lg) {
    grid-template-columns: 2fr 1fr 1fr;
  }

  @include respond-to(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.cartItemContainer {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  border-bottom: 1px solid #ccccccb3;
  padding: 1rem 0;

  @include respond-to(lg) {
    grid-template-columns: 2fr 1fr 1fr;
  }

  @include respond-to(md) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.cartItemDetailsContainer {
  display: flex;

  @include respond-to(md) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .itemContentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include respond-to(md) {
      justify-content: center;
      align-items: center;
    }
  }

  .itemImageContainer {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.cartItemQuantityContainer {
  align-self: center;
}

.cartItemPriceContainer {
  align-self: center;

  @include respond-to(md) {
    text-align: center;
  }
}
