// Utilities: Colors

// Define Color Palette
$primary-color: #007bff;
$secondary-color: #6c757d;
$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;
$light-color: #f8f9fa;
$dark-color: #343a40;
$white-color: #ffffff;
$black-color: #000000;

// Generate Background Color Utilities
.bg-primary {
  background-color: $primary-color !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-danger {
  background-color: $danger-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.bg-info {
  background-color: $info-color !important;
}

.bg-light {
  background-color: $light-color !important;
}

.bg-dark {
  background-color: $dark-color !important;
}

.bg-white {
  background-color: $white-color !important;
}

.bg-black {
  background-color: $black-color !important;
}

// Generate Text Color Utilities
.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-success {
  color: $success-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-info {
  color: $info-color !important;
}

.text-light {
  color: $light-color !important;
}

.text-dark {
  color: $dark-color !important;
}

.text-white {
  color: $white-color !important;
}

.text-black {
  color: $black-color !important;
}
