@import "../../../abstracts/variables";

.textElementContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  // padding: 5px 0;
  max-width: 500px;

  &.customDesignClass {
    // Custom design overrides can be applied here
  }

  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;

    input.defaultInputStyle {
      flex: 1;
      padding: 10px;
      border: 1px solid #ccc;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;

      &:focus {
        outline: none;
      }
    }

    input.customInputClass {
      // Allow custom styles for the input
    }

    .icon {
      position: absolute;
      left: 10px;
      cursor: pointer;
      font-size: 16px;
      color: $dark-color;

      &:hover {
        color: $primary-color;
      }
    }
  }
}
