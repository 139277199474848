@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.productDetailsHeaderContentContainer {
  display: flex;
  gap: 20px;

  @include respond-to("md") {
    flex-direction: column;
  }

  .productMainContentContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 800px;

    .attributesContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .productDescription {
      white-space: pre-line;
    }
  }
}
