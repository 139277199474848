// Sections
.section {
    padding: spacer(5) 0;
  
    &--dark {
      background-color: $dark-color;
      color: $light-color;
    }
  
    &--light {
      background-color: $light-color;
      color: $dark-color;
    }
  
    &--primary {
      background-color: $primary-color;
      color: $light-color;
    }
  }
  