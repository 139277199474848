// Base Button Styles
@import "../abstracts/variables";

.primaryButton {
  display: inline-block;
  font-family: $font-family-body;
  font-size: 1rem;
  padding: spacer(2) spacer(3);
  color: $light-color; // Ensure text color remains fixed
  background-color: $primary-color;
  border: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0s; // Prevent color transition

  &:hover,
  &:focus {
    background-color: darken($primary-color, 10%);
    color: $light-color; // Ensure text color stays the same
  }

  &--secondary {
    background-color: $secondary-color;

    &:hover,
    &:focus {
      background-color: darken($secondary-color, 10%);
      color: $light-color; // Keep text color unchanged
    }
  }

  &--danger {
    background-color: $danger-color;

    &:hover,
    &:focus {
      background-color: darken($danger-color, 10%);
      color: $light-color; // Keep text color unchanged
    }
  }
}
