@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.file-element-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 0;
  max-width: 250px;

  .file-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }

  .file-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  label {
    font-size: 1rem;
  }

  input[type="file"] {
    padding: 10px;
    border: 2px solid $body-bg;
    border-radius: 8px;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: $body-bg;
    }
  }

  @include respond-to(xs) {
    max-width: 100%;

    .file-input-container {
      gap: 10px;
    }

    input[type="file"] {
      padding: 8px;
      border-radius: 6px;
    }
  }

  @include respond-to(sm) {
    max-width: 100%;

    .file-input-container {
      gap: 10px;
    }

    input[type="file"] {
      padding: 8px;
      border-radius: 6px;
    }
  }

  @include respond-to(md) {
    max-width: 100%;

    .file-input-container {
      gap: 10px;
    }

    input[type="file"] {
      padding: 8px;
      border-radius: 6px;
    }
  }
}
