@import "../abstracts/variables";
@import "../abstracts/mixins";

// Containers
.container {
  width: 100%;
  margin: 0 auto;
  padding: 3rem 9rem;

  @include respond-to("md") {
    padding: 1rem 1.5rem;
  }
}

.container-fluid {
  width: 100%;
  padding: 0 spacer(3);
}
