@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.productDetailsShoppingCartButtonsContainer {
  display: flex;
  gap: 10px;

  @include respond-to(md) {
    justify-content: center;
  }

  .quantityButton {
    padding: 0.5rem 1rem;
    font-size: 20px;
    border: 1px solid #c4c4c4;

    @include respond-to(md) {
      padding: 0.5rem 0.5rem;
    }
  }

  .quantityDisplay {
    border-left: none;
    border-right: none;
  }
}
