// Spacing Utilities
@mixin generate-spacing($property) {
    @each $key, $value in $spacers {
      .#{$property}-#{$key} {
        #{$property}: $value !important;
      }
    }
  }
  
  @include generate-spacing(m);
  @include generate-spacing(mt);
  @include generate-spacing(mr);
  @include generate-spacing(mb);
  @include generate-spacing(ml);
  @include generate-spacing(mx);
  @include generate-spacing(my);
  
  @include generate-spacing(p);
  @include generate-spacing(pt);
  @include generate-spacing(pr);
  @include generate-spacing(pb);
  @include generate-spacing(pl);
  @include generate-spacing(px);
  @include generate-spacing(py);
  
  // Flex Utilities
  .flex {
    display: flex !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .justify-start {
    justify-content: flex-start !important;
  }
  .justify-center {
    justify-content: center !important;
  }
  .justify-end {
    justify-content: flex-end !important;
  }
  .justify-between {
    justify-content: space-between !important;
  }
  .align-start {
    align-items: flex-start !important;
  }
  .align-center {
    align-items: center !important;
  }
  .align-end {
    align-items: flex-end !important;
  }
  
  // Visibility Utilities
  .hidden {
    display: none !important;
  }
  .visible {
    display: block !important;
  }
  