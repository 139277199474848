@import "../../../abstracts/variables";

.switchElementContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 500px;
  cursor: pointer; // Ensure the entire switch is clickable

  &.customDesignClass {
    // Custom design overrides can be applied here
  }

  .switchWrapper {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    cursor: pointer; // Ensure it's clickable

    input.defaultSwitchStyle {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2; // Ensure it captures click events
      cursor: pointer;

      &:checked + .slider {
        background-color: $primary-color;
      }

      &:checked + .slider:before {
        transform: translateX(20px);
      }

      &:disabled + .slider {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.3s;
      border-radius: 20px;

      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: 0.3s;
        border-radius: 50%;
      }
    }
  }
}

// 📱 Responsive Adjustments
@media (max-width: 768px) {
  .switchElementContainer {
    // flex-direction: column;
    align-items: flex-start;
  }
}
