// Utilities: Helpers

// Display Utilities
.d-flex {
    display: flex !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-none {
    display: none !important;
  }
  
  // Flexbox Alignment
  .justify-content-start {
    justify-content: flex-start !important;
  }
  
  .justify-content-center {
    justify-content: center !important;
  }
  
  .justify-content-end {
    justify-content: flex-end !important;
  }
  
  .align-items-start {
    align-items: flex-start !important;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  .align-items-end {
    align-items: flex-end !important;
  }
  
  // Positioning
  .position-relative {
    position: relative !important;
  }
  
  .position-absolute {
    position: absolute !important;
  }
  
  .position-fixed {
    position: fixed !important;
  }
  
  .position-static {
    position: static !important;
  }
  
  // Text Utilities
  .text-center {
    text-align: center !important;
  }
  
  .text-left {
    text-align: left !important;
  }
  
  .text-right {
    text-align: right !important;
  }
  
  // Overflow Utilities
  .overflow-hidden {
    overflow: hidden !important;
  }
  
  .overflow-auto {
    overflow: auto !important;
  }
  
  .overflow-visible {
    overflow: visible !important;
  }
  