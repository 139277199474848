@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.checkboxElementContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 500px;
  
    .checkboxWrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      cursor: pointer;
      font-size: $font-size-base;
      color: $dark-color;
      
      input.defaultCheckboxStyle {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        
        &:checked ~ .customCheckbox {
          background-color: $primary-color;
          border-color: $primary-color;
        }
  
        &:checked ~ .customCheckbox:after {
          display: block;
        }
      }
    }
  
    .customCheckbox {
      width: 20px;
      height: 20px;
      border: 2px solid $secondary-color;
      // border-radius: 4px;
      position: relative;
      transition: background 0.3s, border-color 0.3s;
      
      &:after {
        content: "";
        position: absolute;
        left: 6px;
        top: 2px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
      }
    }
  
    .checkboxLabel {
      user-select: none;
    }
  
    .error {
      color: $danger-color;
      font-size: $font-size-sm;
    }
  
    // Responsive Adjustments
    @include respond-to("md") {
      .checkboxWrapper {
        font-size: $font-size-sm;
      }
  
      .customCheckbox {
        width: 18px;
        height: 18px;
      }
    }
  }