// Import Variables
@import "./variables";

// Media Query Mixin
@mixin respond-to($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

// Flexbox Utilities
@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

// Box Shadows
@mixin box-shadow($shadow: $default-box-shadow) {
  box-shadow: $shadow;
}

// Visually Hidden (for accessibility)
@mixin visually-hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Transition
@mixin transition($property: all, $duration: 0.2s, $easing: ease-in-out) {
  transition: $property $duration $easing;
}
