// Utilities: Spacers

// Define Spacing Scale
$spacer-scale: (
  "0": 0px,
  "1": 4px,
  "2": 8px,
  "3": 16px,
  "4": 24px,
  "5": 32px,
  "6": 48px
);

// Generate Margin Utilities
@each $key, $value in $spacer-scale {
  .m-#{$key} {
    margin: $value !important;
  }

  .mt-#{$key} {
    margin-top: $value !important;
  }

  .mb-#{$key} {
    margin-bottom: $value !important;
  }

  .ml-#{$key} {
    margin-left: $value !important;
  }

  .mr-#{$key} {
    margin-right: $value !important;
  }

  .mx-#{$key} {
    margin-left: $value !important;
    margin-right: $value !important;
  }

  .my-#{$key} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
}

// Generate Padding Utilities
@each $key, $value in $spacer-scale {
  .p-#{$key} {
    padding: $value !important;
  }

  .pt-#{$key} {
    padding-top: $value !important;
  }

  .pb-#{$key} {
    padding-bottom: $value !important;
  }

  .pl-#{$key} {
    padding-left: $value !important;
  }

  .pr-#{$key} {
    padding-right: $value !important;
  }

  .px-#{$key} {
    padding-left: $value !important;
    padding-right: $value !important;
  }

  .py-#{$key} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
}
