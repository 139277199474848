@import "../../abstracts/variables";
@import "../../abstracts/mixins";

@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.productCardContainer {
  @include flex(column, center, center);
  width: 280px;
  height: 400px; // Fixed height to ensure uniformity
  @include box-shadow;
  padding: 15px;
  text-align: center;
  @include transition;

  @include respond-to("md") {
    width: 100%;
    max-width: 280px;
    height: auto;
  }

  .primaryButton {
    width: 100%;
    padding: 10px;
  }
}

.productImageContainer {
  width: 100%;
  height: 180px; // Fixed height to maintain consistency
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensures uniformity for images
  }
}

.productContentContainer {
  flex-grow: 1; // Allows content to fill remaining space
  width: 100%;
  text-align: center;
  padding: 10px 0;

  h3 {
    font-size: $font-size-lg;
    font-weight: bold;
    color: $dark-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; // Ensures long product names do not break layout
    width: 100%;
  }

  h4 {
    font-size: $font-size-base;
    font-weight: bold;
    color: $primary-color;
    margin-top: 5px;
  }
}


