@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.categoriesLinksContainer {
  display: flex;
  gap: $spacer;

  .parentCategoryContainer {
    .parentCategoryLink {
      text-decoration: none;
      color: $dark-color;
      padding: $spacer / 2;
      transition: color 0.2s ease;
    }

    .subCategoriesContainer {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100vw; // Full width of the viewport
      background-color: $primary-color_light;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 10px;
      z-index: 10;

      

      .subcategoryLink {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        text-decoration: none;
        color: white; // Text color for better contrast

        .subcategoryImage {
          position: relative;
          width: 150px; // Adjust size as needed
          height: 150px; // Adjust size as needed
          overflow: hidden;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          margin-bottom: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          // Dark overlay
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); // Dark overlay with transparency
            z-index: 1;
          }

          span {
            position: relative;
            z-index: 2; // Ensure text appears above the overlay
            color: white; // Text color
            font-size: 0.9rem;
            font-weight: bold;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7); // Add slight shadow for better visibility
          }
        }

        span {
          margin-top: 5px;
          font-size: 0.8rem;
        }
      }
    }


  }

  // Responsive Design
  @include respond-to("sm") {
    flex-direction: column;

    .subCategoriesContainer {
      flex-direction: column;
      padding: $spacer / 2;

      .subcategoryLink {
        flex: 1 1 100%; // Full width on small screens
      }
    }
  }
}
