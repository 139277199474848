// Navigation
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: spacer(2);
  
    .nav-item {
      margin: 0 spacer(2);
  
      a {
        color: $dark-color;
        font-size: $font-size-base;
  
        &:hover {
          color: $primary-color;
        }
      }
    }
  
    .nav-item.active a {
      font-weight: bold;
      color: $primary-color;
    }
  }
  