@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.productListSortAndFilter {
  .smallScreenFilterTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    gap: 10px;
    margin: 1rem;

    .toggleButton {
      display: flex;
      gap: 10px;
      cursor: pointer;
    }
  }
}

.productListSortAndFilterContainer {
  padding: 15px;
  box-shadow: $default-box-shadow;
  width: 100%;
  max-width: 400px;

  @include respond-to("md") {
    max-width: 100%;
    box-shadow: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    background: white;
  }

  h3 {
    color: $dark-color;
    font-size: $font-size-lg;
  }

  .filterOptions {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .filterCategory {
      background: $body-bg;
      padding: 10px;

      h4 {
        font-size: $font-size-base;
        color: $primary-color;
        margin-bottom: 8px;
      }

      .filterCheckboxes {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include respond-to("md") {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }
  }


}

@include respond-to("md") {
  .productListSortAndFilterContainer .container,
  .productListSortAndFilter .container {
    padding: 0 !important;
  }
}
