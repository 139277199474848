@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";

.SwiperThumbnailSliderGalleryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .mainSwiper {
    width: 100%;
    max-width: 500px;
    position: relative;

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }

    // Change Navigation Button Colors
    .swiper-button-prev,
    .swiper-button-next {
      color: $primary-color !important; // Change arrow color
      font-size: 18px;
      transition: all 0.3s ease;

      &:hover {
        color: darken($primary-color, 10%); // Darker on hover
      }
    }
  }

  .thumbnailSwiper {
    width: 100%;
    max-width: 500px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;

    .swiper-slide {
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.3s ease, transform 0.2s ease-in-out;

      &.swiper-slide-thumb-active {
        opacity: 1;
        transform: scale(1.1); // Add slight zoom effect when selected
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }
  }
}
