// Footer
.footer {
    background-color: $dark-color;
    color: $light-color;
    padding: spacer(3);
  
    .footer-links {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      a {
        color: $light-color;
        transition: color 0.2s;
  
        &:hover,
        &:focus {
          color: lighten($light-color, 20%);
        }
      }
    }
  
    .copyright {
      text-align: center;
      margin-top: spacer(3);
      font-size: $font-size-sm;
    }
  }
  