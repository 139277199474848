// Font Imports
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@font-face {
  font-family: VarelaRound;
  src: url(../../assets/font/VarelaRound-Regular.ttf);
}

// Font Family
$font-family-heading: "VarelaRound", sans-serif;
$font-family-body: "VarelaRound", sans-serif;

// Typography Settings
$heading-font-sizes: (
  h1: 2.5rem,
  h2: 2rem,
  h3: 1.75rem,
  h4: 1.5rem,
  h5: 1.25rem,
  h6: 1rem,
);
