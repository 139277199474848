
// Base Typography
body {
    font-family: $font-family-body;
    font-size: $font-size-base;
    color: $body-color;
    line-height: $line-height-base;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-heading;
    margin-bottom: 0.5rem;
  }
  
  h1 { font-size: map-get($heading-font-sizes, h1); }
  h2 { font-size: map-get($heading-font-sizes, h2); }
  h3 { font-size: map-get($heading-font-sizes, h3); }
  h4 { font-size: map-get($heading-font-sizes, h4); }
  h5 { font-size: map-get($heading-font-sizes, h5); }
  h6 { font-size: map-get($heading-font-sizes, h6); }
  
  p {
    margin-bottom: spacer(3); // Consistent spacing between paragraphs
  }
  
  a {
    color: $primary-color;
    transition: color 0.2s ease-in-out;
  
    &:hover,
    &:focus {
      color: darken($primary-color, 10%);
    }
  }
  
  strong {
    font-weight: bold;
  }
  
  em {
    font-style: italic;
  }
  