@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.productListContainer {
  @include flex(row, flex-start, flex-start);
  flex-wrap: wrap;
  gap: 10px;
  // padding: $spacer;

  // Responsive Design
  @include respond-to("lg") {
    justify-content: center;
  }

  @include respond-to("md") {
    flex-direction: column;
    align-items: center;
  }

  @include respond-to("sm") {
    gap: 5px;
  }
}
