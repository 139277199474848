@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.headerContainer {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $primary-color;
  width: 100%;

  .hamburgerMenu {
    display: none;
    margin-inline-start: 20px;
    cursor: pointer;
  }

  .fullScreen {
    position: absolute;
    background-color: #2b2b2b54;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: -1;
    cursor: pointer;
  }

  .generalLinksContainer {
    position: absolute;
    left: 0;
    padding-inline-end: 20px;
    display: flex;
    gap: 20px;

    font-size: $font-size-lg;
    a {
      color: $dark-color;
    }
  }

  @include respond-to("sm") {
    // flex-direction: column;
    align-items: center;

    .hamburgerMenu {
      display: block;
    }

    .headerContentContainer {
      position: absolute;
      top: 100px;
      width: 80%;
      height: calc(100vh - 100px);
      background-color: $primary-color_light;
      padding-top: 20px;
      box-shadow: -9px -4px 5px -5px rgb(136 136 136 / 45%);

      @include respond-to("sm") {
        padding-inline-start: 20px;
      }
    }
  }
}
