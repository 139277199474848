// Colors
$primary-color: #B1835F;
$primary-color_light: #b1835fad;
$secondary-color: #6c757d;
$light-color: #f8f9fa;


$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;


$info-color: #17a2b8;
$dark-color: #343a40;

$body-bg: #ffffff;
$body-color: #212529;

// Typography
$font-family-base: "Helvetica Neue", Arial, sans-serif;
$font-size-base: 16px;
$line-height-base: 1.5;

// Font Sizes
$font-size-sm: 12px;
$font-size-lg: 20px; // Large font size

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 1.5rem,
  5: 3rem
);

// Breakpoints
$breakpoints: (
  "xs": 0,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px
);

// Z-Index Layers
$z-index-dropdown: 1000;
$z-index-sticky: 1020;
$z-index-fixed: 1030;
$z-index-modal: 1050;
$z-index-tooltip: 1070;

// Shadows
$default-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
