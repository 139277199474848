// Reset CSS
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%; // 16px base
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $font-family-base;
  color: $body-color;
  background-color: $body-bg;
  line-height: $line-height-base;
  direction: rtl;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  list-style: none;
}

button {
  all: unset;
  cursor: pointer;
}

input,
textarea,
select {
  font: inherit;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;

  // Responsive Dialog Sizing
  @media (max-width: 600px) {
    padding: 12px;
  }

  // Ensure dialog content does not exceed max height
  .MuiDialogContent-root {
    overflow-y: auto;
    max-height: 80vh;
  }

  // Center content inside the dialog
  .dialogContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  // Dialog actions container
  .dialogActions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 12px;
  }
}
