@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.productGalleryContaier {
  display: flex;
  gap: 20px;

  @include respond-to("md"){
    flex-direction: column;
  }

  .filterAndSortContainer {
    width: 20%;
    
    @include respond-to("xl") {
      width: 30%;
    }

    @include respond-to("md"){
      width: 100%;
    }
  }

  .listContainer {
    width: 100%;
  }
}
