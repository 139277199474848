.select-element-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0;
    max-width: 100%;
  
    label {
      font-size: 1rem;
      color: #333;
      margin-bottom: 5px;
    }
  
    .search-input {
      padding: 10px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      outline: none;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;
      margin-bottom: 5px;
  
      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }
    }
  
    select {
      padding: 10px;
      background-color: #f9f9f9;
      font-size: 1rem;
      color: #333;
      outline: none;
      transition: border-color 0.3s ease, box-shadow 0.3s ease;
      width: 100%;
  
      &:focus {
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
      }
    }
  
    @media (max-width: 768px) {
      .search-input,
      select {
        font-size: 0.85rem;
        padding: 8px;
      }
    }
  }
  